import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { useMenu } from '../../../context';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { getLink, outOfScopes } from '../../../helpers/CommonHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import useFlatlistVerticalAnimation from '../../../hooks/useFlatlistVerticalAnimation';
import useLazyLoadOnFlatlist from '../../../hooks/useLazyLoadOnFlatlist';
import Hero from '../../molecules/SelectedComponent/Hero';
import Skeleton from './skeleton';
import RailList from './railList';
import { PageContentPropTypes } from './proptypes';
import styles from './styles';
import { makeApiCall } from '../../../middleware/dynamic';
import { ApiRels, contentTypeList } from '../../../helpers/Enums';
import { setFocusCustom } from '../../../helpers/FocusHelper';

/**
 * Page content component of pages
 *
 * @param {object} props - props
 * @param {string} props.urlSlug - urlSlug
 * @param {string} props.prefix - prefix
 * @param {string|boolean} props.leftFocusKey - leftFocusKey
 * @param {string} props.focusKey - focusKey
 * @returns {module:JSX.Element} - JSX.Element
 */
const PageContentWrapper = ({ urlSlug, prefix, leftFocusKey, focusKey }) => {
  const { menuState } = useMenu();
  const memoizedMenuState = useMemo(() => menuState, [menuState]);
  const [isLoading, setIsLoading] = useState(true);
  const [globalFocusedItem, setGlobalFocusedItem] = useState({});
  const [baseRailList, setBaseRailList] = useState([]);
  const { navigate, replaceNavigate } = useCustomNavigation();
  const { dynamicRailList, railFocusIndexSetter } = useLazyLoadOnFlatlist(
    baseRailList,
    prefix
  );
  const { animatedTop, railListRef, onScrollToIndexFailedHandler } =
    useFlatlistVerticalAnimation(globalFocusedItem);

  /**
   * Fetch Page Rail List
   */
  useEffect(() => {
    (async () => {
      if (memoizedMenuState.data?.items) {
        const { url } = getLink(
          memoizedMenuState.data?.items,
          ApiRels.SELF,
          'urlSlug',
          urlSlug
        );

        if (url) {
          const railList = await makeApiCall({ url });

          if (railList) {
            setBaseRailList(
              railList.rails.filter((rail) => outOfScopes(rail.railType))
            );

            // loading finish
            const timeout = setTimeout(() => {
              setIsLoading(false);
              setFocusCustom(focusKey);
            }, 1000);

            return () => {
              clearTimeout(timeout);
            };
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlug, memoizedMenuState?.data?.items, focusKey]);

  /**
   * Rail Item OnEnterPress
   *
   * @param {object} selected - selected item
   */
  const onEnterPress = useCallback(
    (selected) => {
      // is livetv?
      // is the rail item is ViewAll?
      if (prefix === 'livetv-raillist') {
        navigate(NavigationRoutes.live);
      } else if (selected?.viewAll) {
        // NOTE: FlatList cannot change numColumns value at runtime. so it was replaced with navigation.replace method
        replaceNavigate(NavigationRoutes.viewAll, {
          url: selected?.url,
        });
      } else {
        // TODO: check useCallback usage in here
        const { url } = getLink(selected.item, ApiRels.SELF);

        navigate(NavigationRoutes.contentdetail, {
          url: url,
          contentType: contentTypeList[selected?.item?.contentType] || 1,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <View style={styles.contentArea}>
      {isLoading && <Skeleton />}
      <Hero globalFocusedItem={globalFocusedItem} />
      <View style={[styles.railContainer, animatedTop]}>
        <RailList
          prefix={prefix}
          content={dynamicRailList}
          railListRef={railListRef}
          leftFocusKey={leftFocusKey}
          onEnterPress={onEnterPress}
          railFocusIndexSetter={railFocusIndexSetter}
          setGlobalFocusedItem={setGlobalFocusedItem}
          onScrollToIndexFailedHandler={onScrollToIndexFailedHandler}
          baseRailListLength={baseRailList.length}
        />
      </View>
    </View>
  );
};

PageContentWrapper.propTypes = PageContentPropTypes;
PageContentWrapper.whyDidYouRender = false;

export default withFocusable()(memo(PageContentWrapper));
