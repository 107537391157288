import axios from 'axios';
import env from './config';
import { Platform } from 'react-native';

import { getApiKey, apiKeyHandler } from '../../libs/getApiKey';
import refreshAccessToken from '../../libs/refreshAccessToken';

//INFO: We have to find device model
//INFO: https://confluence.digiturk.com.tr/pages/viewpage.action?spaceKey=TOD2&title=Client-Middleware+Comminucation
const selectPlatform = Platform.select({
  ios: 'appletv',
  android: 'androidtv',
  web: 'webtv',
});

const api = axios.create({
  baseURL: env.SmartTv.Endpoint,
  headers: {
    'accept-digi': '',
    'x-client-name': selectPlatform,
    'accept-language': 'EN',
    'x-application': 'tod2',
    Authorization: '',
    'Cache-Control': 'public, max-age=31536000',
    'x-region': 'QA_5264',
  },
});

/**
 * set authorization token to api requests
 *
 * @param {string} token - token
 */
export const setAuth = (token) => {
  api.defaults.headers.Authorization = token;
};

/**
 * set api key to api requests
 *
 * @param {string} arrakis - api key
 */
export const setDigi = (arrakis) => {
  api.defaults.headers['accept-digi'] = arrakis;
};

/**
 * set baseUrl
 *
 * @param {string} url - url
 */
export const setBaseUrl = (url) => {
  api.defaults.baseURL = url;
};

/**
 * Request interceptor
 */
api.interceptors.request.use(async (config) => {
  // API Key Case
  config = await apiKeyHandler(config, api);

  // Other Request Cases

  return config;
});

/**
 * Response interceptor
 */
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;

    switch (status) {
      // 401: Refresh Access Token
      case 401:
        if (!originalRequest.retry) {
          originalRequest.retry = true;
          await refreshAccessToken(api, setAuth);

          return api(originalRequest);
        }

        break;

      // 403: Getting API Key
      case 403:
        if (!originalRequest.retry) {
          originalRequest.retry = true;
          await getApiKey(originalRequest, api);

          return api(originalRequest);
        }

        break;

      // Other Response Cases
    }

    return Promise.reject(error);
  }
);

export default api;
