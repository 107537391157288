export const FONT_FAMILY = {
  THIN: 'Alexandria-Thin',
  EXTRA_LIGHT: 'Alexandria-ExtraLight',
  LIGHT: 'Alexandria-Light',
  REGULAR: 'Alexandria-Regular',
  MEDIUM: 'Alexandria-Medium',
  SEMIBOLD: 'Alexandria-SemiBold',
  BOLD: 'Alexandria-Bold',
  EXTRA_BOLD: 'Alexandria-ExtraBold',
  BLACK: 'Alexandria-Black',
};

/**
 * Font Sizes
 */
export const FONT_SIZES = {
  HEROLARGE: 'HL',
  HEROMEDIUM: 'HM',
  HEROSMALL: 'HS',
  DISPLAYLARGE: 'DL',
  DISPLAYSMALL: 'DS',
  HEADING1: 'H1',
  HEADING2: 'H2',
  HEADING3: 'H3',
  HEADING4: 'H4',
  HEADING5: 'H5',
  HEADING6: 'H6',
  PARAGRAPH1: 'P1',
  PARAGRAPH2: 'P2',
  PARAGRAPH3: 'P3',
  PARAGRAPH4: 'P4',
  OVERLINE1: 'O1',
  OVERLINE2: 'O2',
  OVERLINE3: 'O3',
};

/**
 * Font Values
 */
export const FONT_VALUES = {
  HL: { FONT_SIZE: 102, LINE_HEIGHT: 154 },
  HM: { FONT_SIZE: 82, LINE_HEIGHT: 124 },
  HS: { FONT_SIZE: 68, LINE_HEIGHT: 102 },
  DL: { FONT_SIZE: 56, LINE_HEIGHT: 84 },
  DS: { FONT_SIZE: 48, LINE_HEIGHT: 72 },
  H1: { FONT_SIZE: 40, LINE_HEIGHT: 60 },
  H2: { FONT_SIZE: 32, LINE_HEIGHT: 48 },
  H3: { FONT_SIZE: 28, LINE_HEIGHT: 42 },
  H4: { FONT_SIZE: 24, LINE_HEIGHT: 36 },
  H5: { FONT_SIZE: 20, LINE_HEIGHT: 30 },
  H6: { FONT_SIZE: 18, LINE_HEIGHT: 28 },
  P1: { FONT_SIZE: 16, LINE_HEIGHT: 24 },
  P2: { FONT_SIZE: 14, LINE_HEIGHT: 22 },
  P3: { FONT_SIZE: 12, LINE_HEIGHT: 18 },
  P4: { FONT_SIZE: 10, LINE_HEIGHT: 16 },
  O1: { FONT_SIZE: 14, LINE_HEIGHT: 22 },
  O2: { FONT_SIZE: 12, LINE_HEIGHT: 18 },
  O3: { FONT_SIZE: 10, LINE_HEIGHT: 16 },
};
/**
 * Video Modes
 */
export const VIDEO_MODES = {
  LIVE: 'live',
  VOD: 'vod',
  LIVE_EVENT: 'event',
};
/**
 * Spacing Sizes
 */
export const SPACING_SIZES = {
  SPACINGXXSM: 'SXXSM',
  SPACINGXSM: 'SXSM',
  SPACINGSSX: 'SSX',
  SPACINGSM: 'SSM',
  SPACINGMD: 'SMD',
  SPACINGBIG: 'SBIG',
  SPACINGXBIG: 'SXBIG',
  SPACINGXXBIG: 'SXXBIG',
  SPACINGXXXBIG: 'SXXXBIG',
  SPACINGLG: 'SLG',
  SPACINGXLG: 'SXLG',
  SPACINGXXLG: 'SXXLG',
  SPACINGXXXLG: 'SXXXLG',
  SPACINGHUGE: 'SHG',
  SPACINGXHUGE: 'SXHG',
  SPACINGXXHUGE: 'SXXHG',
  SPACINGXXXHUGE: 'SXXXHG',
};

/**
 * SPACING Values
 */
export const SPACING_VALUES = {
  SXXSM: 4,
  SXSM: 8,
  SSX: 10,
  SSM: 12,
  SMD: 16,
  SBMD: 18,
  SBIG: 20,
  SXBIG: 24,
  SXXBIG: 28,
  SXXXBIG: 32,
  SLG: 40,
  SXLG: 48,
  SXXLG: 64,
  SXXXLG: 80,
  SHG: 96,
  SXHG: 128,
  SXXHG: 160,
  SXXXHG: 192,
};

export const contentTypeList = {
  MOVIE: 1,
  SERIES: 2,
  EVENT_AND_TOURNAMENT: 3,
  GAME: 4,
  channel: 5,
};

export const SectionTypes = {
  Default: 'default',
  Main: 'Main',
  Name: 'Name',
  Avatar: 'Avatar',
  ProfileType: 'ProfileType',
  PinCode: 'PinCode',
  DeleteProfile: 'DeleteProfile',
};

export const ProfileTypes = {
  STANDART: 'STANDART',
  RESTRICTED: 'RESTRICTED',
  KID: 'KID',
};

export const ApiRels = {
  SELF: 'self',
  MENU: 'menu',
  KIDS_MENU: 'kids-menu',
  VIEW_ALL: 'view-all',
  EPISODE_ALL: 'episode-all',
  GENERATE_AUTH_CODE: 'generate-auth-code',
  LOGIN_WITH_AUTH_CODE: 'login-with-auth-code',
  FAVORITES: {
    ADD: 'add-favorite',
    DELETE: 'delete-favorite',
    CHECK: 'check-favorites',
  },
  PROFILE: {
    GET_AVATARS: 'get-avatars',
    GET_PROFILES: 'get-profiles',
    SWITCH_PROFILE: 'session-exchange-for-profile',
    SAVE_PROFILE: 'save-profile',
    DELETE_PROFILE: 'delete-profile',
    VERIFY_PIN_CODE: 'verify-pin-code',
    UPDATE_PROFILE: 'update-profile',
  },
  SEARCH: {
    SUGGESTIONS_KEYS: 'suggestions-keys',
    SEARCHABLE_KEYS: 'searchable-keys',
    SEARCH_RESULT: 'search-result',
  },
  CDN: 'cdn',
};
