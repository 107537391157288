import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import Text from '../../../atoms/Text';
import Icon from '../../../atoms/Icon';

import styleFunction from './styles';
import PropList from './proptypes';

/**
 * Menu Button With Icon Text
 *
 * @param {object} props - props
 * @param {string} props.text - text
 * @param {any} props.icon - icon
 * @param {boolean} props.onlyIcon - visible only icon
 * @param {boolean} props.focused - focused
 * @param {boolean} props.selected - selected
 * @param {any} props.style - style
 * @returns {module:JSX.Element} - JSX.Element
 */
const MenuButton = ({
  text,
  icon,
  onlyIcon = false,
  focused,
  selected,
  style,
}) => {
  const styles = styleFunction(focused, selected);

  return (
    <View style={[styles.container, style && style]}>
      <View style={styles.iconArea}>
        <Icon icon={icon} {...styles.icon} />
      </View>
      {!onlyIcon && (
        <View style={styles.textArea}>
          <Text text={text} style={styles.text} />
        </View>
      )}
    </View>
  );
};

MenuButton.whyDidYouRender = false;
MenuButton.propTypes = PropList;

export default withFocusable()(MenuButton);
