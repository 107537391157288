import React, { memo } from 'react';

import { StyleSheet, View } from 'react-native';

import { PlayerWidgetPropTypes } from './proptypes';
import VodPlayer from '../../atoms/VodPlayer';
import LivePlayer from '../../atoms/LivePlayer';
import { FONT_FAMILY, FONT_SIZES, VIDEO_MODES } from '../../../helpers/Enums';
import { GetScaledValue } from '@digiturk/screen-size';
import { windowHeight } from '../../../helpers/CommonHelper';
import Text from '../../atoms/Text';
import colors from '../../../helpers/Colors';
/**
 * Player Widget
 *
 * @param {object} props - props
 * @param {object} props.source - source
 * @param {string} props.streamFormat - streamFormat
 * @param {object} props.drmData - drmData
 * @param {Function} props.onDurationChange - on duration change callback -> seekableDuration: number
 * @param {Function} props.onCurrentTimeUpdate - on current time update callback -> currentTime: number
 * @param {Function} props.onLoad - on load callback -> { currentPosition: number, duration: number, naturalSize: object, audioTracks: array, textTracks: array, videoTracks: array }
 * @param {object} props.refPlayer - reference to the player, to use seek method you should use like refPlayer.current.seek(seconds)
 * @param {object} props.onBufferHandler - on Buffer Handler
 * @param {object} props.content - content
 * @param {boolean} props.isStatisticsView - isStatisticsView -> true: statistic mode, false: full mode
 * @param {string} props.videoType - videoType
 * @returns {module:JSX.Element} - JSX.Element
 */
const PlayerWidget = ({
  source,
  streamFormat = 'DASH',
  drmData,
  onDurationChange,
  onCurrentTimeUpdate,
  onLoad,
  refPlayer,
  onBufferHandler,
  content,
  isStatisticsView,
  videoType,
}) => {
  if (videoType === VIDEO_MODES.VOD) {
    return (
      <View style={styles.container}>
        <VodPlayer
          id={'player-for-video-screen'}
          onLoad={onLoad}
          refPlayer={refPlayer}
          onCurrentTimeUpdate={onCurrentTimeUpdate}
          onDurationChange={onDurationChange}
          onBufferHandler={onBufferHandler}
          source={source}
        />
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        {isStatisticsView && (
          <View style={styles.statisticTitle}>
            <Text
              text={'Real Madrid - Barcelona'}
              color={colors.shades00}
              fontFamily={FONT_FAMILY.SEMIBOLD}
              size={FONT_SIZES.HEADING2}
            />
          </View>
        )}
        <View style={isStatisticsView ? styles.statisticsView : styles.flex}>
          <LivePlayer
            id={'player-for-live-screen'}
            onLoad={onLoad}
            refPlayer={refPlayer}
            onCurrentTimeUpdate={onCurrentTimeUpdate}
            source={source}
          />
        </View>
      </View>
    );
  }
};

PlayerWidget.whyDidYouRender = false;
PlayerWidget.propTypes = PlayerWidgetPropTypes;

export default memo(PlayerWidget);

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  flex: {
    flex: 1,
  },
  statisticsView: {
    position: 'absolute',
    start: GetScaledValue(875),
    top: windowHeight + GetScaledValue(370),
    width: GetScaledValue(1040),
    minWidth: GetScaledValue(1040),
    height: GetScaledValue(585),
    borderRadius: GetScaledValue(24),
    overflow: 'hidden',
  },
  statisticTitle: {
    position: 'absolute',
    start: GetScaledValue(875),
    top: windowHeight + GetScaledValue(300),
  },
});
