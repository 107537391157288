import { StyleSheet, View } from 'react-native';
import React from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import Text from '../../atoms/Text';
import {
  FONT_FAMILY,
  FONT_SIZES,
  VIDEO_MODES,
  contentTypeList,
} from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import {
  AudioList,
  PlayStroke,
  Player,
  Subtitle,
  VideoQuality,
} from '../../../assets/icons';
import Subtitles from './Subtitles';
import AnimatedButton from '../../molecules/buttons/AnimatedButton';
import ToolTipWrapper from '../../organisms/ToolTipWrapper';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import MODALS from '../../organisms/WatchModal/modals';

/**
 * ControllerContainer
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {string} props.buttonPrefix - buttonPrefix
 * @param {object} props.contentInfo - contentInfo
 * @param {Array} props.audioList - audioList
 * @param {Array} props.qualityList - qualityList
 * @param {string} props.downFocusKey - downFocusKey
 * @param {Function} props.setSelectedSubtitle - setSelectedSubtitle
 * @param {Function} props.handleAudioSelect - handleAudioSelect
 * @param {Function} props.handleModal - handleModal
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {boolean} props.userActivity - userActivity
 * @param {boolean} props.showSkipButton - showSkipButton
 * @param {Function} props.onEnterGoLiveButton - onEnterGoLiveButton
 * @param {boolean} props.isTrackLive - isTrackLive
 * @param {Function} props.handleVideoQuality - handleVideoQuality
 * @returns {module:JSX.Element} - JSX.Element
 */
const ControllerContainer = ({
  videoType,
  buttonPrefix,
  contentInfo,
  audioList,
  qualityList,
  downFocusKey,
  setSelectedSubtitle,
  handleAudioSelect,
  handleModal,
  subtitleSettingsCallback,
  userActivity,
  showSkipButton,
  onEnterGoLiveButton = () => null,
  isTrackLive,
  handleVideoQuality,
}) => {
  const isLiveTv = videoType === VIDEO_MODES.LIVE;
  const isTvSeries = contentInfo?.contentType.type === contentTypeList.SERIES;

  const actionButtons = [
    {
      icon: PlayStroke,
      label: 'Next Episode',
      isAvailable: isTvSeries,
      onSelectHandler: () => null,
    },
    {
      icon: Subtitle,
      label: 'Subtitles',
      isAvailable: Subtitles?.length > 0,
      data: Subtitles,
      onSelectHandler: (selected) => setSelectedSubtitle(selected.url),
    },
    {
      icon: AudioList,
      label: 'Audio Track',
      isAvailable: audioList?.length > 0,
      data: audioList,
      onSelectHandler: (selected) => handleAudioSelect(selected),
    },
    {
      icon: Player,
      label: 'Similar Movies',
      isAvailable: true,
      onSelectHandler: () => {
        handleModal(MODALS.MORE_EPISODES);
      },
    },
    {
      icon: VideoQuality,
      label: 'Quality',
      data: qualityList,
      isAvailable: qualityList?.length > 0,
      onSelectHandler: (option) => handleVideoQuality(option),
    },
  ];

  const liveActionButtons = [
    {
      icon: AudioList,
      label: 'Audio Track',
      isAvailable: audioList?.length > 0,
      data: audioList,
      onSelectHandler: (selected) => handleAudioSelect(selected),
    },
  ];

  return (
    <View style={styles.container}>
      <View>
        <Text
          text={contentInfo?.title}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          size={FONT_SIZES.DISPLAYLARGE}
          color={colors.shades00}
        />
        {isTvSeries && (
          <>
            <Text
              text={'S1 | E1 Episode Name'}
              fontFamily={FONT_FAMILY.REGULAR}
              size={FONT_SIZES.HEADING2}
              color={colors.shades00}
            />

            {showSkipButton && (
              <AnimatedButton
                startTime={5000}
                endTime={10000}
                text="Skip Intro"
                focusKey="skip-intro"
                rightFocusKey="nextButton"
                downFocusKey="progress-bar"
                upFocusKey={'4kButton'}
                leftFocusKey={false}
              />
            )}
          </>
        )}

        {isLiveTv && !isTrackLive && (
          <BasicButton
            size={buttonSize.big}
            theme={buttonThemes.neutral700}
            themeFocused={buttonThemes.blackWhite}
            text="Go Live"
            onEnterPress={onEnterGoLiveButton}
            style={{ marginTop: GetScaledValue(20) }}
            focusKey="goLive"
            rightFocusKey={buttonPrefix + '0'}
            downFocusKey={'progress-bar'}
            upFocusKey={'4kButton'}
            leftFocusKey={'statistics-screen'}
          />
        )}
      </View>

      <ToolTipWrapper
        data={isLiveTv ? liveActionButtons : actionButtons}
        buttonPrefix={buttonPrefix}
        downFocusKey={downFocusKey}
        userActivity={userActivity}
        leftFocusKey={isLiveTv && !isTrackLive ? 'goLive' : false}
        subtitleSettingsCallback={subtitleSettingsCallback}
      />
    </View>
  );
};

export default ControllerContainer;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: GetScaledValue(40),
  },
});
