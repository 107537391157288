/* eslint-disable require-jsdoc */
import { useCallback, useRef } from 'react';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * FlatList Vertical Animation
 *
 * @param {Function} globalFocusedItem - globalFocusedItem
 * @returns {object} - return object
 */
const useFlatlistVerticalAnimation = (globalFocusedItem) => {
  const railTop = GetScaledValue(globalFocusedItem?.railTop || 635);

  const railListRef = useRef();
  let scrollY = 0;

  const animatedTop = () => {
    const paddingTop = railTop;

    return { paddingTop };
  };

  /**
   * onScrollToIndexFailedHandler
   */
  const onScrollToIndexFailedHandler = useCallback(
    ({ index, averageItemLength }) => {
      railListRef?.current?.scrollToOffset({
        offset: index * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        railListRef?.current?.scrollToIndex({
          index: index,
          animated: true,
        });
      }, 100);
    },
    []
  );

  return {
    animatedTop,
    railListRef,
    scrollY,
    onScrollToIndexFailedHandler,
  };
};

export default useFlatlistVerticalAnimation;
