import React, { Suspense, memo, useMemo, useEffect, useState } from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import {
  railIsCollection,
  marginBottom,
  titleHeight,
  getRailItemConfigurations,
  railInitialData,
  getLink,
} from '../../../helpers/CommonHelper';
import { ApiRels } from '../../../helpers/Enums';
import { makeApiCall } from '../../../middleware/dynamic';
import railComponentsMap from '../../molecules/SelectedComponent/Rails';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Fetches the rail content data based on the given item.
 *
 * @param {object} item - The rail item for which the data is fetched.
 * @returns {object} - The fetched rail content data.
 */
const useRailContentData = (item) => {
  const [railContentData, setRailContentData] = useState(railInitialData);

  useEffect(() => {
    /**
     * Fetches the data for the given item.
     *
     * @returns {Promise<void>} - The fetched data.
     */
    const fetchData = async () => {
      if (!item) return;

      try {
        const { url } = getLink(item, ApiRels.SELF);
        const response = await makeApiCall({ url });

        if (response?.items?.length > 0) {
          setRailContentData({
            ...response,
            collectionWallpaper: item.media
              ? Object.values(item.media)[0]
              : null,
          });
        }
      } catch (error) {
        console.error('Error fetching rail content data:', error);
      }
    };

    fetchData();
  }, [item]);

  return railContentData;
};

/**
 * Calculates the container style based on the item and its configuration.
 *
 * @param {object} item - The rail item.
 * @param {number} height - The height of the rail item.
 * @param {object} position - The position of the rail item.
 * @returns {object} - The calculated container style.
 */
const useContainerStyle = (item, height, position) => {
  return useMemo(() => {
    const containerHeight =
      GetScaledValue(height) +
      marginBottom +
      titleHeight +
      (position?.top || 0);

    return {
      height: railIsCollection(item?.railType)
        ? GetScaledValue(height + 720 - 120)
        : containerHeight,
      marginBottom,
    };
  }, [height, item, position]);
};

/**
 * Determines the correct rail component based on the rail type.
 *
 * @param {object} item - The rail item.
 * @returns {React.Component} - The selected component for the rail type.
 */
const useSelectedComponent = (item) => {
  return useMemo(() => {
    return railIsCollection(item?.railType)
      ? railComponentsMap.Collection
      : railComponentsMap[item?.railType] || railComponentsMap.Default;
  }, [item]);
};

/**
 * Page Horizontal Rail Item component.
 *
 * @param {object} props - Component props.
 * @param {object} props.item - The rail item.
 * @param {number} props.index - Index of the rail item.
 * @param {string} props.prefix - Prefix for focus keys.
 * @param {string} props.leftFocusKey - The left focus key.
 * @param {Function} props.onEnterPress - Handler for enter press event.
 * @param {Function} props.setGlobalFocusedItem - Sets the global focused item.
 * @param {number} props.baseRailListLength - The length of the base rail list.
 * @returns {module:JSX.Element} - The rendered rail item component.
 */
const RailItem = ({
  item,
  index,
  prefix,
  leftFocusKey,
  onEnterPress,
  setGlobalFocusedItem,
  baseRailListLength,
}) => {
  const { height, position } = getRailItemConfigurations(item?.railType);

  const railContentData = useRailContentData(item);
  const containerStyle = useContainerStyle(item, height, position);
  const SelectedComponent = useSelectedComponent(item);

  return (
    <View style={containerStyle}>
      <Suspense>
        <SelectedComponent
          title={item.title}
          railType={item.railType}
          railContentData={railContentData}
          onEnterPress={onEnterPress}
          focusKeyPrefix={`${prefix}-${index}`}
          focusKey={`${prefix}-${index}`}
          leftFocusKey={leftFocusKey}
          upFocusKey={index === 0 ? false : `${prefix}-${index - 1}`}
          downFocusKey={
            index < baseRailListLength - 1 ? `${prefix}-${index + 1}` : false
          }
          setGlobalFocusedItem={setGlobalFocusedItem}
          viewAll={item.viewAll}
          railOffsetStartOfPage={0}
          railOffsetInlineStart={190}
          railWidth={1920}
          railItemGap={20}
        />
      </Suspense>
    </View>
  );
};

export default memo(withFocusable({ trackChildren: true })(RailItem));
