import { View } from 'react-native';
import React, { useCallback, useLayoutEffect, useState } from 'react';

import styleFunction from './styles';
import PropList from './proptypes';
import Text from '../../../atoms/Text';

import { withFocusable } from '@digiturk/react-spatial-navigation';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Animated Button
 *
 * @param {object} props - props
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {any} props.themeCustom - color custom
 * @param {number} props.startTime - start time
 * @param {number} props.endTime - end time
 * @returns {module:JSX.Element} - JSX.Element
 */
const AnimatedButton = ({ text, focused, themeCustom, startTime, endTime }) => {
  const [btnWidth, setBtnWidth] = useState(0);
  const styles = styleFunction({ focused, themeCustom, btnWidth });

  const calculateButtonWidth = useCallback((textWidth, pxToWord = 10) => {
    return textWidth?.length * pxToWord;
  }, []);

  useLayoutEffect(() => {
    setBtnWidth(calculateButtonWidth(text, GetScaledValue(22)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <View style={styles.animatedContainer}>
      <View style={[styles.animatedInner]} />
      <View style={styles.animatedButton} />
      <Text text={text} style={styles.text} />
    </View>
  );
};

AnimatedButton.whyDidYouRender = false;
AnimatedButton.propTypes = PropList;

export default withFocusable()(AnimatedButton);
