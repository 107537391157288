import axios from 'axios';
import env from '../../middleware/services/config';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { encryptKey } from '../encryptKey';
import { getStorage } from '../../context/storage';
import { authActions } from '../../context/Auth';
import storageKeys from '../../context/storageKeys';

const refreshTokenUrl = `${env.SmartTv.ApiTest}api/v${env.SmartTv.ApiVersion}/auth/refresh`;

/**
 * Refresh Access Token
 *
 * @param {object} api - api axios object
 * @param {Function} setAuth - authorization of header set function
 * @returns {object} - error response
 */
const refreshAccessToken = async (api, setAuth) => {
  const refreshToken = await getStorage(storageKeys.auth);
  const acceptDigi = await AsyncStorage.getItem('arrakis');

  const data = {
    refreshToken: refreshToken.rt,
    // TODO: what should be the redirect url
    redirectUri: 'https://jwt.ms',
  };

  const headers = {
    headers: {
      ...api.defaults.headers,
      'accept-digi': encryptKey(acceptDigi),
    },
  };

  const response = await axios.post(refreshTokenUrl, data, headers);

  setAuth(`Bearer ${response.data.data.at}`);
  authActions.setAuth({ at: response.data.data.at, rt: response.data.data.rt });
};

export default refreshAccessToken;
