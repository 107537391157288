import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import useLocalization from '../../../libs/localization/useLocalization';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import {
  isWeb,
  trailer,
  isValidRailType,
  windowHeight,
} from '../../../helpers/CommonHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import Background from '../../atoms/Posters/Background';
import FocusableSection from './section';
import Skeleton from './skeleton';
import styles from './styles';
import { useRoute } from '@react-navigation/native';
import { makeApiCall } from '../../../middleware/dynamic';
import Arrows from './Arrows';

/**
 * ContentDetail
 *
 * @returns {module:JSX.Element} -
 */
const ContentDetail = () => {
  const railListRef = useRef();
  const { url, contentType } = useRoute().params;
  const { goBack } = useCustomNavigation();
  const { languageDirection } = useLocalization();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(null);

  let offsetArr = [GetScaledValue(0), GetScaledValue(1080)];

  useHardwareBackPress(() => {
    goBack();
  }, true);

  const keyExtractor = useCallback((_item, _index) => `detail_${_index}`, []);

  /**
   * Filters rails to include only valid railTypes
   *
   * @param {Array} rails - The array of rails to filter
   * @returns {Array} - The filtered array of rails with valid railTypes
   */
  const filterRails = (rails) => {
    return rails.filter((rail) => isValidRailType(rail.railType));
  };

  useEffect(() => {
    (async () => {
      if (url) {
        const response = await makeApiCall({ url });
        const filteredRails = filterRails(response.rails);

        setData(filteredRails);
        setTimeout(() => setIsLoading(false), 1000);
        setTimeout(() => {
          setFocusCustom('detail-0-button-0');
        }, 100);
      }
    })();
  }, [url]);

  const onScrollToIndexFailedHandler = useCallback(
    ({ index: idx, averageItemLength }) => {
      railListRef?.current?.scrollToOffset({
        offset: idx * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        railListRef?.current?.scrollToIndex({
          index: idx,
          animated: true,
        });
      }, 100);
    },
    []
  );

  const counter = useCallback(
    (_data, idx) => {
      if (idx !== 0) {
        offsetArr[idx + 1] = offsetArr[idx] + GetScaledValue(_data);
      }

      return offsetArr[idx];
    },
    [offsetArr]
  );

  const onBecameFocus = useCallback(
    (coordinate, selected) => {
      const player = trailer.get();

      if (player) {
        if (selected.index === 0 && player.isPaused()) player.play();
        else player.pause();
      }

      setTimeout(() => {
        if (isWeb) {
          railListRef.current?.scrollToOffset({
            offset: counter(coordinate.height, selected?.index),
            animated: true,
          });
        } else {
          railListRef.current?.scrollToIndex({
            index: selected?.index,
            animated: true,
          });
        }
      }, 100);

      setTitle({
        up: data[selected.index - 1]?.title,
        down: data[selected.index + 1]?.title,
      });
    },
    [counter, data]
  );

  /**
   * Render item
   */
  const renderItem = useCallback(
    ({ item, index: idx }) => (
      <FocusableSection
        item={item}
        index={idx}
        setImage={() => null}
        focusKeyPrefix={'detail'}
        contentType={contentType}
        contentLength={data.length}
        onBecameFocused={onBecameFocus}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  if (!data) return null;

  return (
    <Background blurRadius={50} source={''}>
      <View dir={languageDirection} style={styles.fullScreenContainer}>
        {isLoading && <Skeleton contentType={contentType} />}
        <FlatList
          ref={railListRef}
          data={data}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          removeClippedSubviews={true}
          scrollEnabled={false}
          onScrollToIndexFailed={onScrollToIndexFailedHandler}
          ListFooterComponent={<View style={{ height: windowHeight }} />}
        />
        <Arrows title={title} contentType={contentType} />
      </View>
    </Background>
  );
};

export default ContentDetail;
