import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import {
  getCurrentFocusKey,
  setFocusCustom,
} from '../../../helpers/FocusHelper';
import { getId, resetId, exitApp } from '../../../helpers/CommonHelper';
import useLocalization from '../../../libs/localization/useLocalization';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import FocusableSidebar from '../../templates/Sidebar';
import PageContent from '../../templates/PageContent';
import { IndexPropTypes } from './proptypes';
import styles from './styles';
import { useRoute } from '@react-navigation/native';

/**
 * Home screen
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Home = () => {
  const lastFocusKey = useRef();
  const dateTime = useRoute().params?.date;

  const { languageDirection } = useLocalization();

  const [date, setDate] = useState(dateTime);

  useEffect(() => {
    if (dateTime) setDate(dateTime);
  }, [dateTime]);

  const onNavigateBack = useCallback(() => {
    const currentFocusKey = getCurrentFocusKey();

    if (currentFocusKey?.indexOf('sidebar') === -1) {
      const currentId = getId(currentFocusKey);

      if (currentId != null) {
        if (parseInt(currentId, 10) > 0) {
          lastFocusKey.current = resetId(currentFocusKey);
          setFocusCustom(lastFocusKey.current);
        } else {
          setFocusCustom('sidebar');
        }
      } else {
        setFocusCustom('sidebar');
      }
    } else {
      exitApp();
    }
  }, []);

  useHardwareBackPress(onNavigateBack, [onNavigateBack]);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`home-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'home-content'}
        urlSlug={'home'}
        onNavigateBack={onNavigateBack}
      />
      <PageContent
        key={'home'}
        urlSlug={'home'}
        prefix={'home-raillist'}
        focusKey={'home-content'}
        leftFocusKey={'sidebar'}
      />
    </View>
  );
};

Home.propTypes = IndexPropTypes;
Home.whyDidYouRender = false;

export default memo(Home);
