import { View } from 'react-native';
import React, { useEffect } from 'react';
import styles from './styles';
import Text from '../../atoms/Text';

import { Language, TODLogoPNG } from '../../../assets/icons';
import Icon from '../../atoms/Icon';
import colors from '../../../helpers/Colors';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import Image from '../../atoms/Image';
import useLocalization from '../../../libs/localization/useLocalization';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import NavigationRoutes from '../../../navigation/NavigationRoutes';

/**
 * A functional component representing the landing page.
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Landing = () => {
  const localization = useLocalization();

  const navigation = useCustomNavigation();

  useEffect(() => {
    setFocusCustom('activate');
  });
  const data = {
    title: localization.isRTL
      ? 'رياضة وترفيه بلا حدود'
      : 'Limitless Sport & Entertainment',
    description: localization.isRTL
      ? 'انغمس في عالم من الاحتمالات اللامتناهية مع TOD - الحدود التالية في مجال التفوق في البث التلفزيوني عبر الإنترنت'
      : 'Immerse Yourself in a World of Endless Possibilities with TOD - The Next Frontier in OTT Excelience',
    image:
      'https://www.picclickimg.com/1GIAAOSwxlJmH5EW/Movie-Film-POSTERS-Vintage-Retro-Print-Man-Cave.webp',
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Icon
            icon={TODLogoPNG}
            height={60}
            width={145}
            style={styles.iconContainer}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.title} text={data.title} />
          <Text
            numberOfLines={3}
            style={styles.description}
            multiline
            text={data.description}
            color={colors.neutral200}
          />
          <View style={styles.buttonContainers}>
            <BasicButton
              text="Activate"
              style={styles.button}
              theme={buttonThemes.transparent16}
              themeFocused={buttonThemes.primary600}
              focusKey={'activate'}
              size={buttonSize.medium}
              rightFocusKey={'browse'}
              downFocusKey={'language'}
              onEnterPress={() => navigation.navigate(NavigationRoutes.signIn)}
            />

            <BasicButton
              text="Browse"
              style={styles.button}
              theme={buttonThemes.transparent16}
              themeFocused={buttonThemes.primary600}
              focusKey={'browse'}
              leftFocusKey={'activate'}
              downFocusKey={'language'}
              onEnterPress={() => navigation.navigate(NavigationRoutes.home)}
            />
          </View>
          <View style={styles.buttonContainers}>
            <BasicButton
              text={`${localization.isRTL ? 'English' : 'العربية (AR)'}`}
              theme={buttonThemes.transparent16}
              themeFocused={buttonThemes.primary600}
              onEnterPress={() => {
                if (localization.isRTL) {
                  localization.setLocalization(null);
                } else {
                  localization.setLocalization('ar');
                }
              }}
              focusKey={'language'}
              icon={Language}
              upFocusKey={'activate'}
            />
          </View>
        </View>
      </View>
      <View>
        <Image style={styles.image} source={data.image} />
      </View>
    </View>
  );
};

export default Landing;
