import React, { useCallback, useRef } from 'react';
import { FlatList, View } from 'react-native';

import styles from './style';

import Item from './item';
import { Substitution } from '../../../assets/icons';

/**
 * Event Boxes
 *
 * @param {object} props - props
 * @param {string} props.prefix - prefix
 * @param {Array} props.commentaries - commentaries
 * @param {string} props.downFocusKey - downFocusKey
 * @returns {module:JSX.Element} - JSX.Element
 */
export const EventBoxes = ({ prefix, commentaries, downFocusKey }) => {
  const sliderRef = useRef();

  const onBecameFocused = useCallback((_, selected) => {
    sliderRef.current.scrollToIndex({ index: selected.index, animated: true });
  }, []);

  /**
   * Render Item
   *
   * @param {object} props - props
   * @param {object} props.item - item
   * @param {object} props.index - item
   * @returns {object} - object
   */
  const renderItem = ({ item, index }) => (
    <Item
      index={index}
      unfocusedText={item.time}
      text={item.comment}
      icon={Substitution}
      focusKey={prefix + index}
      onBecameFocused={onBecameFocused}
      rightFocusKey={
        index < commentaries.length - 1 ? prefix + (index + 1) : false
      }
      leftFocusKey={index === 0 ? false : prefix + (index - 1)}
      upFocusKey="progress-bar"
      downFocusKey={downFocusKey}
    />
  );

  return (
    <View style={styles.container}>
      <FlatList
        ref={sliderRef}
        horizontal={true}
        data={commentaries}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={false}
        keyExtractor={(_, index) => index.toString()}
      />
    </View>
  );
};

EventBoxes.whyDidYouRender = false;

export default EventBoxes;
