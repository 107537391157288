import { getStorage } from '../../context/storage';
import storageKeys from '../../context/storageKeys';
import api from './api';
import env from './config';

const baseURL = env.SmartTv.ApiTest + 'api/v' + env.SmartTv.ApiVersion;

export const AppApis = {
  MenuApis: async () => {
    const token = await getStorage(storageKeys.auth);

    api.defaults.headers.Authorization = `Bearer ${token.at}`;

    return api.get(baseURL + '/ui/menus');
  },
  DynamicGet: (url) => api.get(url),
  DynamicPost: (url, body, headers) => api.post(url, body, headers),
  DynamicPut: (url, body, headers) => api.put(url, body, headers),
  DynamicDelete: (url) => api.delete(url),
};
