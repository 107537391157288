import React, { memo, Suspense, useMemo, useState, useEffect } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import { makeApiCall } from '../../../middleware/dynamic';
import { getLink, isFullScreenSection } from '../../../helpers/CommonHelper';
import { ApiRels } from '../../../helpers/Enums';
import SelectedSectionComponent from '../../molecules/SelectedComponent/Section';

/**
 * Renders a section component.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - The item object.
 * @param {number} props.index - The index of the item.
 * @param {string} props.focusKeyPrefix - The focusKeyPrefix string.
 * @param {Function} props.setGlobalFocusedItem - The setGlobalFocusedItem function.
 * @param {Function} props.setImage - The setImage function.
 * @param {string|boolean} props.hasFocusedChild - hasFocusedChild
 * @param {number} props.contentLength - The content length.
 * @param {number} props.contentType - The content type.
 * @returns {module:JSX.Element} The rendered section component.
 */
const Section = ({
  item,
  index,
  focusKeyPrefix,
  contentLength,
  setGlobalFocusedItem,
  setImage,
  hasFocusedChild,
  contentType,
}) => {
  const [railContentData, setRailContentData] = useState(null);

  useEffect(() => {
    (async () => {
      if (item) {
        const { url } = getLink(item, ApiRels.SELF);
        const response = await makeApiCall({ url });

        if (response) {
          setRailContentData(response);
        }
      }
    })();
  }, [item]);

  const containerStyle = useMemo(() => {
    return {
      paddingTop: isFullScreenSection(item.railType)
        ? 0
        : contentType === 3
        ? GetScaledValue(272)
        : GetScaledValue(192),
      opacity: hasFocusedChild ? 1 : 0.6,
    };
  }, [contentType, hasFocusedChild, item.railType]);

  const SelectedComponent = useMemo(
    () =>
      SelectedSectionComponent[item.railType] ||
      SelectedSectionComponent.default,
    [item.railType]
  );

  const upFocusKey = index > 0 ? `${focusKeyPrefix}-${index - 1}` : false;
  const downFocusKey =
    index < contentLength - 1 ? `${focusKeyPrefix}-${index + 1}` : false;

  if (!railContentData) return null;

  return (
    <View style={containerStyle}>
      <Suspense>
        <SelectedComponent
          title={item.title}
          contentData={railContentData}
          contentType={contentType}
          index={index}
          focusKeyPrefix={`${focusKeyPrefix}-${index}`}
          focusKey={`${focusKeyPrefix}-${index}`}
          setGlobalFocusedItem={setGlobalFocusedItem}
          upFocusKey={upFocusKey}
          downFocusKey={downFocusKey}
          setImage={setImage}
          railType={item.railType}
          railOffsetStartOfPage={0}
          railOffsetInlineStart={140}
          railWidth={1920}
          railItemGap={40}
        />
      </Suspense>
    </View>
  );
};

export default memo(withFocusable({ trackChildren: true })(Section));
