import React, { useEffect, useState } from 'react';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { Platform, View } from 'react-native';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { ArrowLeftBold } from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import Language from './language';
import { translate } from '@digiturk/i18n';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import Subtitle from '../../molecules/Subtitle';

import styles from './styles';
import BackgroundBlur from '../../atoms/BackgroundBlur';

import { CheckStroke } from '../../../assets/icons';
/**
 * Hidden focus placeholder component
 *
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {Function} props.handleSubtitleTextStyle - handleSubtitleTextStyle
 * @param {Function} props.handleModal - handleModal
 * @returns {module:JSX.Element} - JSX.Element
 */

// eslint-disable-next-line require-jsdoc
const SubtitleSettingModal = ({
  subtitleSettingsCallback,
  handleSubtitleTextStyle,
  handleModal,
}) => {
  const [color, setColor] = useState('#fff');

  const [isSelectedSize, setIsSelectedSize] = useState({
    regularSize: true,
    smallSize: false,
    bigSize: false,
  });

  const [isSelectedColor, setIsSelectedColor] = useState({
    default: true,
    secondary: false,
    tertiary: false,
    quaternary: false,
  });

  const [selectedBackground, setSelectedBackground] = useState(
    colors.transparent
  );

  const [fontSize, setFontSize] = useState(FONT_SIZES.HEADING2);
  const [selectedFocus, setSelectedFocus] = useState('regular-size');

  const rightButtonListData = [
    {
      id: '1',
      size: buttonSize.big,
      text: 'Aa',
      theme: buttonThemes.ghostTransparent16,
      themeFocused: buttonThemes.primary00,
      style: styles.buttonContainer,
      textAreaStyle: styles.borderWhite,
      focusKey: 'default',
      downFocusKey: 'secondary',
      upFocusKey: null,
      leftFocusKey: selectedFocus,
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedColor.default,
      onEnterPress: () => {
        handleSubtitleTextStyle({
          color: colors.text,
          backgroundColor: colors.transparent,
        });
        changeSubtitleStyle(colors.text, colors.transparent);
        activateOnlyOne('default', setIsSelectedColor);
      },
    },
    {
      id: '2',
      size: buttonSize.big,
      text: 'Aa',
      theme: buttonThemes.ghostTransparent16,
      themeFocused: buttonThemes.primary00,
      style: styles.buttonContainer,
      textAreaStyle: styles.blackWhite,
      focusKey: 'secondary',
      downFocusKey: 'tertiary',
      upFocusKey: 'default',
      leftFocusKey: { selectedFocus },
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedColor.secondary,
      onEnterPress: () => {
        handleSubtitleTextStyle({
          color: colors.text,
          backgroundColor: colors.background,
        });
        changeSubtitleStyle(colors.text, colors.background);
        activateOnlyOne('secondary', setIsSelectedColor);
      },
    },
    {
      id: '3',
      size: buttonSize.big,
      text: 'Aa',
      theme: buttonThemes.ghostPrimaryTransparent16,
      themeFocused: buttonThemes.primary400,
      style: styles.buttonContainer,
      textAreaStyle: styles.blackWhite,
      focusKey: 'tertiary',
      downFocusKey: 'quaternary',
      rightFocusKey: null,
      upFocusKey: 'secondary',
      leftFocusKey: selectedFocus,
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedColor.tertiary,
      onEnterPress: () => {
        handleSubtitleTextStyle({
          color: colors.primary,
          backgroundColor: colors.background,
        });
        changeSubtitleStyle(colors.primary, colors.background);
        activateOnlyOne('tertiary', setIsSelectedColor);
      },
    },
    {
      id: '4',
      size: buttonSize.big,
      text: 'Aa',
      theme: buttonThemes.ghostShadesTransparent16,
      themeFocused: buttonThemes.primary,
      style: styles.buttonContainer,
      textAreaStyle: styles.whiteBlack,
      focusKey: 'quaternary',
      downFocusKey: null,
      upFocusKey: 'tertiary',
      leftFocusKey: selectedFocus,
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedColor.quaternary,
      onEnterPress: () => {
        changeSubtitleStyle(colors.background, colors.text);
        activateOnlyOne('quaternary', setIsSelectedColor);
      },
    },
  ];
  const leftButtonListData = [
    {
      id: '1',
      size: buttonSize.big,
      text: translate(Language.REGULAR),
      theme: isSelectedSize.regularSize
        ? buttonThemes.ghostPrimaryTransparent16
        : buttonThemes.ghostTransparent16,
      themeFocused: buttonThemes.primary,
      style: styles.buttonContainer,
      focusKey: 'regular-size',
      downFocusKey: 'small-size',
      rightFocusKey: 'default',
      upFocusKey: null,
      leftFocusKey: null,
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedSize.regularSize,
      onEnterPress: () => {
        setFontSize(FONT_SIZES.HEADING2);
        handleSubtitleTextStyle({ fontSize: FONT_SIZES.HEADING2 });
        setSelectedFocus('regular-size');
        activateOnlyOne('regularSize', setIsSelectedSize);
      },
    },
    {
      id: '2',
      size: buttonSize.big,
      text: 'Small',
      theme: isSelectedSize.smallSize
        ? buttonThemes.ghostPrimaryTransparent16
        : buttonThemes.ghostTransparent16,
      themeFocused: buttonThemes.primary,
      style: styles.buttonContainer,
      focusKey: 'small-size',
      downFocusKey: 'big-size',
      rightFocusKey: 'default',
      upFocusKey: 'regular-size',
      leftFocusKey: null,
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedSize.smallSize,
      onEnterPress: () => {
        setFontSize(FONT_SIZES.HEADING5);
        handleSubtitleTextStyle({ fontSize: FONT_SIZES.HEADING5 });
        setSelectedFocus('small-size');
        activateOnlyOne('smallSize', setIsSelectedSize);
      },
    },
    {
      id: '3',
      size: buttonSize.big,
      text: 'Big',
      theme: isSelectedSize.bigSize
        ? buttonThemes.ghostPrimaryTransparent16
        : buttonThemes.ghostTransparent16,
      themeFocused: buttonThemes.primary,
      style: styles.buttonContainer,
      focusKey: 'big-size',
      downFocusKey: null,
      upFocusKey: 'small-size',
      rightFocusKey: 'default',
      leftFocusKey: false,
      icon: CheckStroke,
      iconPosition: 'right',
      iconWidth: Platform.OS === 'android' ? 40 : 24,
      iconHeight: Platform.OS === 'android' ? 40 : 24,
      isSelected: isSelectedSize.bigSize,
      onEnterPress: () => {
        setFontSize(FONT_SIZES.DISPLAYLARGE);
        handleSubtitleTextStyle({ fontSize: FONT_SIZES.DISPLAYLARGE });
        setSelectedFocus('big-size');
        activateOnlyOne('bigSize', setIsSelectedSize);
      },
    },
  ];

  useEffect(() => setFocusCustom(selectedFocus), [selectedFocus]);

  /**
   *
   * @param {string} cl - color
   * @param {string} bg - background
   */
  function changeSubtitleStyle(cl, bg) {
    setColor(cl);
    setSelectedBackground(bg);
  }

  /**
   *
   * @param {string} key - key
   * @param {Function} _func - _func
   */
  const activateOnlyOne = (key, _func) => {
    _func((prevState) => {
      return {
        ...Object.keys(prevState).reduce(
          (acc, k) => ({ ...acc, [k]: false }),
          {}
        ),
        [key]: true,
      };
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <BasicButton
          icon={ArrowLeftBold}
          theme={buttonThemes.ghostWhite}
          text={'Back'}
          focusKey="close-modal"
          downFocusKey={'regular-size'}
          onEnterPress={() => {
            handleModal(null);
            setFocusCustom('player-activity-button-0');
          }}
        />
      </View>

      <View style={styles.box}>
        <View style={styles.size}>
          <Text text="Size" size="DL" fontFamily={FONT_FAMILY.SEMIBOLD} />
          {leftButtonListData &&
            leftButtonListData.map((item) => (
              <BasicButton
                size={item.size}
                text={item.text}
                theme={item.theme}
                themeFocused={item.themeFocused}
                style={item.style}
                focusKey={item.focusKey}
                downFocusKey={item.downFocusKey}
                rightFocusKey={item.rightFocusKey}
                upFocusKey={item.upFocusKey}
                leftFocusKey={item.leftFocusKey}
                icon={item.icon}
                iconPosition={item.iconPosition}
                iconWidth={item.iconWidth}
                iconHeight={item.iconHeight}
                isSelected={item.isSelected}
                onEnterPress={item.onEnterPress}
              />
            ))}
        </View>

        <View style={styles.color}>
          <Text text="Color" size="DL" fontFamily={FONT_FAMILY.SEMIBOLD} />

          {rightButtonListData &&
            rightButtonListData.map((item) => (
              <BasicButton
                size={item.size}
                text={item.text}
                theme={item.theme}
                themeFocused={item.themeFocused}
                style={item.style}
                textAreaStyle={item.textAreaStyle}
                focusKey={item.focusKey}
                downFocusKey={item.downFocusKey}
                upFocusKey={item.upFocusKey}
                leftFocusKey={item.leftFocusKey}
                icon={item.icon}
                iconPosition={item.iconPosition}
                iconWidth={item.iconWidth}
                iconHeight={item.iconHeight}
                isSelected={item.isSelected}
                onEnterPress={item.onEnterPress}
              />
            ))}
        </View>
      </View>
      <View style={styles.subTitle}>
        <Subtitle
          text="This is what your subtitles will look like"
          fontSize={fontSize}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          style={styles.wbSubtitle}
          color={color}
          background={selectedBackground}
        />
      </View>
      <BackgroundBlur />
    </View>
  );
};

export default withFocusable()(SubtitleSettingModal);
