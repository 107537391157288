import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import {
  getCurrentFocusKey,
  setFocusCustom,
} from '../../../helpers/FocusHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { getId, resetId, exitApp } from '../../../helpers/CommonHelper';
import useLocalization from '../../../libs/localization/useLocalization';
import FocusableSidebar from '../../templates/Sidebar';

import { IndexPropTypes } from './proptypes';
import styles from './styles';
import { useRoute } from '@react-navigation/native';
import PageContent from '../../templates/PageContent';

/**
 * Creates a mylist screen view
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Mylist = () => {
  const [date, setDate] = useState(0);
  const { date: dateTime } = useRoute().params;
  const lastFocusKey = useRef();
  const { languageDirection } = useLocalization();

  useEffect(() => {
    if (dateTime) setDate(dateTime);
  }, [dateTime]);

  /**
   * on navigate back method
   */
  const onNavigateBack = () => {
    if (getCurrentFocusKey()?.indexOf('sidebar') === -1) {
      const currentId = getId(getCurrentFocusKey());

      if (currentId != null) {
        if (parseInt(currentId, 10) > 0) {
          lastFocusKey.current = resetId(getCurrentFocusKey());
          setFocusCustom(lastFocusKey.current);
        } else {
          setFocusCustom('sidebar');
        }
      } else {
        setFocusCustom('sidebar');
      }
    } else exitApp();
  };

  useHardwareBackPress(() => {
    onNavigateBack();
  }, true);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`mylist-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'mylist-content'}
        urlSlug={'my-list'}
        onNavigateBack={onNavigateBack}
      />
      <PageContent
        key={'mylist'}
        prefix={'mylist-raillist'}
        focusKey={'mylist-content'}
        leftFocusKey={'sidebar'}
        urlSlug={'my-list'}
      />
    </View>
  );
};

Mylist.propTypes = IndexPropTypes;
Mylist.whyDidYouRender = false;

export default Mylist;
