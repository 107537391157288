const environment = {
  name: 'production',
  SmartTv: {
    Endpoint: '',
    EndpointTest: '',
    EndpointReg: '',
    EndpointDisaster: '',
    ApiTest: 'https://navigation.tod2-test.beiniz.biz/',
    ApiRegression: 'https://navigation.tod2-reg.beiniz.biz/',
    ApiLive: 'https://navigation.tod2.beiniz.biz/',
    ApiVersion: '1',
  },
};

export default environment;
