import { View } from 'react-native';
import React from 'react';
import Text from '../../atoms/Text';
import PropList from './proptypes';
import styles from './styles';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';

/**
 * AgeContentRating Component
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const AgeContentRating = () => {
  const ratingText =
    'We advise parental guidance.Some content may not be suitable for children';
  const formattedRatingText = ratingText.replaceAll('.', '.\n');

  return (
    <View style={[styles.container]}>
      <Text
        fontFamily={FONT_FAMILY.SEMIBOLD}
        size={FONT_SIZES.HEADING1}
        text="PG"
      />
      <View style={styles.verticalLine} />

      <View style={styles.ratintText}>
        <Text
          fontFamily={FONT_FAMILY.SEMIBOLD}
          numberOfLines={2}
          size={FONT_SIZES.HEADING6}
          text={formattedRatingText}
        />
      </View>
    </View>
  );
};

AgeContentRating.propTypes = PropList;
export default React.memo(AgeContentRating);
