import React, { useCallback, useEffect, useRef, useState } from 'react';
import PlayerProperties from '../../templates/PlayerMenuProperties';
import PlayerWidget from '../../molecules/PlayerWidget';
import styles from './styles';
import HiddenFocusPlaceHolder from './hiddenFocusPlaceHolder';

import { setFocusCustom } from '../../../helpers/FocusHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { View } from 'react-native';
import SubtitleSettingModal from '../../organisms/SubtitleSettingModal';
import PlayerSubtitle from '../../molecules/PlayerSubtitle';

import { useFocusEffect, useRoute } from '@react-navigation/native';
import { getLink, isWeb } from '../../../helpers/CommonHelper';
import { VIDEO_MODES } from '../../../helpers/Enums';
import MODALS from '../../organisms/WatchModal/modals';
import FourKQR from '../../templates/FourKQR';
import WatchModal from '../../organisms/WatchModal';
import MoreEpisodes from '../MoreEpisodes';
import LongPause from '../../organisms/LongPause';

import { makeApiCall } from '../../../middleware/dynamic';

import {
  getStorage,
  setStorage,
  removeStorage,
} from '../../../context/storage';

import storageKeys from '../../../context/storageKeys';
import EventManager from '../../../helpers/EventEmitter';

const ACTIVITY_TIMEOUT = 5000;
const LONG_PAUSE_TIMEOUT = 15000;

const TIMESTAMP_KEYS = [
  storageKeys.playerTimestamp,
  storageKeys.playerTimestampExpiration,
];

/**
 * creates a video screen view
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Video = () => {
  const refPlayer = useRef();
  const activityTimeout = useRef();
  const longPauseTimeout = useRef();
  const { selectedItem: contentVod, cdnInfo } = useRoute().params;

  const { goBack } = useCustomNavigation();

  const [savedTime, setSavedTime] = useState(0);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isPlayerReady, setIsPlayerReady] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [subtitleTrack, setSubtitleTracks] = useState(null);
  const [qualityOptions, setQualityOptions] = useState(null);
  const [audioTracks, setAudioTracks] = useState(null);

  const [userActivity, setUserActivity] = useState(true);

  const [selectedSubtitleUrl, setSelectedSubtitleUrl] = useState(null);
  const [subtitleTextStyle, setSubtitleTextStyle] = useState({});

  const [activeModal, setActiveModal] = useState(null);
  const [currentFocus, setCurrentFocus] = useState('');
  const [isLongPressActive, setIsLongPressActive] = useState(false);

  const [playerInfo, setPlayerInfo] = useState(null);
  const [source, setSource] = useState(null);

  const destroyAndGoBack = useCallback(() => {
    saveTimestamp();
    goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModal]);

  useEffect(() => {
    setFocusCustom('progress-bar');
    getSavedTimestamp();
    checkAndRemoveExpiredTimestamp();
  }, [checkAndRemoveExpiredTimestamp]);

  useEffect(() => {
    if (activeModal === null) {
      EventManager.on('focuschanged', (data) => {
        setCurrentFocus(data);
        setUserActivity(true);
      });

      EventManager.on('longpress', (data) => {
        setIsLongPressActive(data);
      });
    }
  }, [activeModal]);

  useHardwareBackPress(() => {
    if (activeModal === null) {
      destroyAndGoBack();
    } else {
      handleModal(null);
      setFocusCustom('progress-bar');
    }
  }, true);

  useFocusEffect(
    useCallback(() => {
      if (activeModal === null) {
        if (currentFocus !== 'hidden-focus-placeholder') {
          activityTimeout.current = setTimeout(() => {
            if (!isLongPressActive) {
              setUserActivity(false);
            }
          }, ACTIVITY_TIMEOUT);
        } else setUserActivity(false);

        return () => {
          clearTimeout(activityTimeout.current);
        };
      } else {
        setUserActivity(false);
        clearTimeout(activityTimeout.current);
      }
    }, [activeModal, currentFocus, isLongPressActive])
  );

  useFocusEffect(
    useCallback(() => {
      if (!userActivity && isPaused) {
        longPauseTimeout.current = setTimeout(() => {
          handleModal(MODALS.LONG_PAUSE);
        }, LONG_PAUSE_TIMEOUT);
      }

      return () => clearTimeout(longPauseTimeout.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userActivity, isPaused])
  );

  const setSelectedSubtitle = useCallback(
    (subtitle) => {
      selectedSubtitleUrl
        ? setSelectedSubtitleUrl(null)
        : setSelectedSubtitleUrl(subtitle);
    },
    [selectedSubtitleUrl]
  );

  const handleModal = useCallback((modal) => {
    if (refPlayer.current) {
      refPlayer.current.pause();
    }

    setActiveModal(modal);
  }, []);

  /**
   * Remove keys from async storage
   */
  const removeTimestampKeys = async () => {
    await Promise.all(TIMESTAMP_KEYS.map((key) => removeStorage(key)));
  };

  /**
   * Save current timestamp to async storage
   */
  const saveTimestamp = async () => {
    try {
      const timestamp = await refPlayer.current.getCurrentTime();
      const expirationTime = 3 * 60 * 1000;
      const expireAt = Date.now() + expirationTime;

      await setStorage(storageKeys.playerTimestamp, timestamp.toString());
      await setStorage(
        storageKeys.playerTimestampExpiration,
        expireAt.toString()
      );

      setTimeout(async () => {
        await removeTimestampKeys();
      }, expirationTime);
    } catch (error) {
      console.error('Failed to save timestamp:', error);
    }
  };

  /**
   * Check and remove expired timestamp from async storage
   */
  const checkAndRemoveExpiredTimestamp = async () => {
    try {
      const expireAt = parseInt(
        await getStorage(storageKeys.playerTimestampExpiration),
        10
      );

      if (Date.now() >= expireAt) {
        await removeTimestampKeys();
      } else {
        setTimeout(async () => {
          await removeTimestampKeys();
        }, expireAt - Date.now());
      }
    } catch (error) {
      console.error('Failed to check and remove expired timestamp:', error);
    }
  };

  /**
   * Get saved timestamp from async storage
   */
  const getSavedTimestamp = async () => {
    try {
      const savedTimestamp = await getStorage(storageKeys.playerTimestamp);

      if (savedTimestamp !== null) {
        setSavedTime(Math.floor(savedTimestamp));
      }
    } catch (error) {
      console.error('Failed to load saved timestamp:', error);
    }
  };

  /**
   * On load handler player - set player, audio,total time and subtitle tracks
   *
   * @param {object} player - player
   */
  const onLoadHandler = useCallback(
    async (_player) => {
      if (!_player) return;

      refPlayer.current = _player;
      setTotalTime(await _player.getDuration());

      setAudioTrack(_player);
      setSubtitleTrack(_player);
      setQualityTrack(_player);

      if (savedTime > 0) {
        _player.seek(savedTime);
      }

      !_player.isPlaying() && _player.play();
    },
    [setAudioTrack, setQualityTrack, setSubtitleTrack, savedTime]
  );

  /**
   * Set audio track - For web and native loads different audio tracks
   *
   * @param {object} _player - player
   */
  const setAudioTrack = useCallback((_player) => {
    if (!isWeb) {
      (async () => {
        const availableAudioTracks = await _player.getAvailableAudioTracks();

        setAudioTracks(availableAudioTracks);
      })();
    } else setAudioTracks(_player.getAvailableAudio());
  }, []);

  /**
   * Set subtitle track - For web and native loads different subtitle tracks
   *
   * @param {object} _player - player
   */
  const setSubtitleTrack = useCallback((_player) => {
    if (!isWeb) {
      (async () => {
        const availableSubtitles = await _player.getAvailableSubtitles();

        setSubtitleTracks(availableSubtitles);
      })();
    } else setSubtitleTracks(_player.subtitles.list());
  }, []);

  /**
   * Set Quality Settings For VOD Content
   */
  const setQualityTrack = useCallback((_player) => {
    if (!isWeb) {
      (async () => {
        const availableVideoQualities =
          await _player.getAvailableVideoQualities();

        setQualityOptions(availableVideoQualities);
      })();
    } else setQualityOptions(_player.getAvailableVideoQualities());
  }, []);

  /**
   * On current time update handler - callback player current time calls from atoms/player
   *
   * @param {object} TimeChangedEvent - time changed event
   */
  const onCurrentTimeUpdateHandler = useCallback((TimeChangedEvent) => {
    const event = TimeChangedEvent.time || TimeChangedEvent.currentTime;

    setPlayerCurrentTime(Math.floor(event));
    setIsPaused(false);
  }, []);

  /**
   * On enter press handler for hidden focus placeholder
   * - play pause callback
   * - set focus to progress bar
   *
   * @returns {void}
   */
  const onEnterPressHandlerHiddenFocusPlaceholder = useCallback(() => {
    setFocusCustom('progress-bar');
    setUserActivity(!userActivity);
    setIsPaused(!isPaused);
    playPauseToggle();
  }, [isPaused, userActivity, playPauseToggle]);

  /**
   * On enter press handler for Progress bar   * - set focus to progress bar
   *
   * @returns {void}
   */
  const onEnterPressHandlerProgressBar = useCallback(() => {
    setIsPaused(!isPaused);
    playPauseToggle();
  }, [isPaused, playPauseToggle]);

  /**
   * play pause callback
   * - play pause player
   * - set is paused state
   */
  const playPauseToggle = useCallback(() => {
    if (!isPaused) refPlayer.current.pause();
    else refPlayer.current.play();
  }, [isPaused]);

  /**
   * On buffer handler - buffer state
   * - set is player ready state
   */
  const onBufferHandler = useCallback((buffer) => {
    if (buffer.isBuffering) {
      setIsPlayerReady(false);
    } else {
      setIsPlayerReady(true);
    }
  }, []);

  /**
   * Subtitle settings callback
   * - set modal show state
   *
   * @returns {void}
   */
  const subtitleSettingsCallback = () => {
    //setActiveModal(MODALS.SUBTITLES);
    handleModal(MODALS.SUBTITLES);
  };

  const handleSubtitleTextStyle = useCallback((_subtitleTextStyle) => {
    setSubtitleTextStyle(!_subtitleTextStyle);
  }, []);

  const activityHandlerWithFocus = useCallback(() => {
    setUserActivity(true);

    setFocusCustom('progress-bar');
  }, []);

  const updatePlayerInfo = useCallback(
    ({ streamFormat, cdnProvider, cdnUri, cdnInfo }) => {
      setPlayerInfo({
        contentId: contentVod?.id,
        assetId: contentVod?.videoAssetId,
        channelId: 5,
        streamFormatType: streamFormat,
        alternativeStreamFormat: streamFormat === 'DASH' ? 'HLS' : 'DASH',
        cdnProvider: cdnProvider,
        cdnUri: cdnUri,
        hashValue: cdnInfo?.hashValue,
      });
    },
    [cdnInfo, contentVod]
  );

  useEffect(() => {
    (async () => {
      if (cdnInfo) {
        const {
          cdnUri,
          streamFormat,
          // isAlternative,
          hasDrm,
          cdnProvider,
          mediaId,
          // cdnStatus,
        } = cdnInfo.cdnInfos.find((x) => x);

        if (hasDrm) {
          updatePlayerInfo({ streamFormat, cdnProvider, cdnUri, cdnInfo });
        }

        streamFormat === 'DASH'
          ? setSource({
              dash: cdnUri,
              hls: null,
              ticket: null,
              mediaId: mediaId,
            })
          : setSource({
              dash: null,
              hls: cdnUri,
              ticket: null,
              mediaId: mediaId,
            });
      }
    })();
  }, [cdnInfo]);

  useEffect(() => {
    const ticketInfo = getLink(cdnInfo, 'ticket');

    try {
      if (playerInfo) {
        makeApiCall({
          method: ticketInfo?.method,
          url: ticketInfo.url,
          body: playerInfo,
        })
          .then((response) => {
            const { ticket } = response?.tickets.find(
              (item) => item.ticketType === 'DRM'
            );

            setSource((prevSource) => ({ ...prevSource, ticket: ticket }));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (err) {
      console.error('>>> err', err);
    }
  }, [playerInfo]);

  return (
    <View style={styles.pageContainer}>
      <View style={styles.pageContainer}>
        {source?.ticket && (
          <PlayerWidget
            onLoad={onLoadHandler}
            refPlayer={refPlayer}
            onCurrentTimeUpdate={onCurrentTimeUpdateHandler}
            onBufferHandler={onBufferHandler}
            content={contentVod}
            source={source}
            videoType={VIDEO_MODES.VOD}
          />
        )}

        <HiddenFocusPlaceHolder
          focusKey={'hidden-focus-placeholder'}
          upFocusKey={activityHandlerWithFocus}
          downFocusKey={activityHandlerWithFocus}
          rightFocusKey={activityHandlerWithFocus}
          leftFocusKey={activityHandlerWithFocus}
          onEnterPress={onEnterPressHandlerHiddenFocusPlaceholder}
        />

        <PlayerSubtitle
          currentTime={playerCurrentTime}
          selectedSubtitle={selectedSubtitleUrl}
          containerStyle={null}
          textStyle={subtitleTextStyle}
        />

        <PlayerProperties
          videoType={VIDEO_MODES.VOD}
          totalTime={totalTime}
          refPlayer={refPlayer}
          isPaused={isPaused}
          contentVod={contentVod}
          audioList={audioTracks}
          setIsPaused={setIsPaused}
          userActivity={userActivity}
          qualityList={qualityOptions}
          isPlayerReady={isPlayerReady}
          currentTime={playerCurrentTime}
          setSelectedSubtitle={setSelectedSubtitle}
          handleSubtitleTextStyle={handleSubtitleTextStyle}
          playPauseCallback={onEnterPressHandlerProgressBar}
          subtitleSettingsCallback={subtitleSettingsCallback}
          handleModal={handleModal}
          isLongPressActive={isLongPressActive}
        />
      </View>

      {activeModal === MODALS.SUBTITLES && (
        <SubtitleSettingModal
          handleModal={handleModal}
          subtitleSettingsCallback={subtitleSettingsCallback}
          handleSubtitleTextStyle={handleSubtitleTextStyle}
        />
      )}

      {activeModal === MODALS.FOUR_K && (
        <WatchModal children={<FourKQR handleModal={handleModal} />} />
      )}
      {activeModal === MODALS.MORE_EPISODES && (
        <WatchModal
          children={
            <MoreEpisodes
              contentPageUrl={
                'https://caladan.tod2-test.beiniz.biz/api/v2/pages/series/the-crown'
              }
            />
          }
        />
      )}
      {activeModal === MODALS.LONG_PAUSE && (
        <WatchModal children={<LongPause handleModal={handleModal} />} />
      )}
    </View>
  );
};

export default Video;
