import { StyleSheet, View } from 'react-native';
import React from 'react';
import { ArrowDownBold, ArrowTopBold } from '../../../assets/icons';
import ArrowWithText from '../../molecules/ArrowWithText';
import colors from '../../../helpers/Colors';
import { GetScaledValue } from '@digiturk/screen-size';
import Image from '../../atoms/Image';
import { contentTypeList } from '../../../helpers/Enums';

/**
 * Arrows component.
 *
 * @param {object} props - The component props.
 * @param {string} props.title - The title string.
 * @param {string} props.contentType - The content type.
 * @returns {module:JSX.Element} The rendered component.
 */
const Arrows = ({ title, contentType }) => {
  const hasLogo = contentType === contentTypeList.GAME && !!title?.up;

  return (
    <View style={[styles.container]}>
      <View style={styles.rowContainer}>
        {!!title?.up && (
          <ArrowWithText
            icon={ArrowTopBold}
            text={title.up}
            iconStyle={styles.iconStyle}
          />
        )}

        {hasLogo && (
          <Image
            source={
              'https://fake-api-media.s3.eu-west-1.amazonaws.com/images/slider/330x150/a0b72cbb80314acfb578405c2ee7d10c.png'
            }
            resizeMode="contain"
            style={styles.logo}
          />
        )}
      </View>

      {!!title?.down && (
        <ArrowWithText
          icon={ArrowDownBold}
          text={title.down}
          iconStyle={styles.iconStyle}
        />
      )}
    </View>
  );
};

export default Arrows;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: GetScaledValue(60),
    bottom: GetScaledValue(60),
    left: GetScaledValue(140),
    right: GetScaledValue(140),
    justifyContent: 'space-between',
    zIndex: 3,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconStyle: {
    color: colors.shades00,
    width: 40,
    height: 40,
  },
  logo: {
    width: GetScaledValue(265),
    height: GetScaledValue(120),
  },
});
